import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
    Copyright © {{getCurrentYearCopyRight}} Simone De Lorenzis
    </span>
	<a href="https://www.iubenda.com/privacy-policy/73984961" rel="noreferrer nofollow" target="_blank">Privacy Policy</a>
    <div class="socials">
      <a href="https://it.linkedin.com/in/simone-de-lorenzis?trk=profile-badge" target="_blank" class="ion ion-social-linkedin"></a>
      <a href="https://www.facebook.com/simone.delorenzis1/" target="_blank" class="ion ion-social-facebook"></a>
      <a href="https://www.instagram.com/simone.delorenzis/" target="_blank" class="ion ion-social-instagram"></a>
    </div>
  `,
})
export class FooterComponent implements OnInit{
  
  getCurrentYearCopyRight;

  ngOnInit(): void {
	this.getCurrentYearCopyRight = new Date().getFullYear();
  }
}
